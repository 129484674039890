input{
    text-align: center;
    background-color: black;
    color: whitesmoke;
    width: 70%;
    border: none;
    margin-top: 5%;
    padding-bottom: 1%;
    border-bottom: 1px solid whitesmoke;
}

button{
    background-color: rgb(150, 0, 0);
    margin-top: 1%;
    padding: 1% 30%;
    font-family: 'Arial Regular';
    letter-spacing: 3px;
    font-weight: bolder;
    cursor: pointer;
}

@media (max-width: 1000px) {
    form{
        margin: 0 10%;
        position: relative;
        right: 10%;
    }

    input{
       width: 110%;
       border-radius: 0; 
    }

    button{
        width: 120%;
        text-align: center;
           
    }

    a{
        font-size: x-small;
    }
}