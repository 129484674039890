.ui.menu {
    background: #000000;
    z-index: 1030;
    font-size: large;
    position: fixed;
    padding-top: 1%;
  }

  .nav {
    padding-top: 0;
    font-family: 'Arial';
    width: 100%;
    text-align: center;
    box-shadow: 2px 1px 15px black;
  }
  
  #home, #about, #services{
    background-color: #000000;
    color: whitesmoke;
    transition: 2s;
    font-size: larger;
    text-decoration: none;
    padding: 0 5%;
    letter-spacing: 2px;
    font-family: 'Arial';
  }

  #about{
    margin-right: 5%;
  }
  
  #home:hover, #services:hover, #about:hover{
    box-shadow: 0px 1px 1px gray;
    cursor: pointer;
  }

  img{
    float: left;
    max-width: 6%;
    margin-top: 0;
    padding-top: 0;
  }

  @media (max-width: 500px) {
    .ui.menu{
      font-size: 9px;
      height: fit-content;
      padding: 10% 0 5% 0;
    }

    .ui.menu img{
      display: none;
    }
  }