body{
    min-height: 100vh;
    font-family: 'Arial Regular';
    overflow-x: hidden;
    min-width: 100vw;
    display: flex;
}

::-webkit-scrollbar{
    display: none;
}

.homepage{
    background-color: black;
    color: whitesmoke;
    text-align: center;
    letter-spacing: 1px;
    min-width: 100vw;
    
}

.image-spot{
    width: 100%;
    justify-content: center;
    position: relative;
   display: flex;
}

a{
    color: whitesmoke;
    text-decoration: none ;
}

.logo{
    
    width:160px;
    min-height:40px;
    background-color:#dadada;
    
    text-align:center;
}

#img2{
    max-height: 200px;
}

.tagline{
    margin: 0;
    background-color: rgb(150, 0, 0);
    color: whitesmoke;
    font-size: xx-large;
}

.tagline1{
    margin: 0;
    background-color: rgb(150, 0, 0);
    color: whitesmoke;
    font-size: xx-large;
    position: absolute;
    top: 100%;
    width: 100%;
}

.about-home{
    padding: 5% 15%;
}

.grabber{
    font-size: 64px;
    margin: 0 10%;
    font-weight: lighter;
    padding: 5% 0;
    font-family: sans-serif !important;
}

.container{
    font-family: 'Arial Regular';
    letter-spacing: 0;
    display: inline-flex;
    width: 80%;
    color: whitesmoke;
}

.img-container{
    position: relative;
    width: 50%;
}


.txt-container{
    text-align: left;
    width: 50%;
    padding-left: 2%;
    font-size: xx-large;
    color: whitesmoke;
    margin: 0;
    
}

#li1{
    display: inline-flex;
    color: rgb(150, 0, 0);
    font-size: 38px;
}

.txt-container span{
    position: relative;
    left: -5%;
}

#guarantee{
    
    font-size: 40px;
    font-family: 'Arial Narrow';
}


.rompala-home-logo {
    display: flex;
    padding-top: 2%;
    position: relative;
    background-size: cover;
    background: url('https://www.youtube.com/watch?v=nVWAdxc7zfQ');
    max-height: 70vh;
}

video{
    object-fit: cover; 
    
    width: 100%;
}

#img2{
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}

.learn-container{
    font-weight: light;
    margin: 0 20%;
}

.video-examples{
    display: inline-flex;
    width: 100%;
}

.video-card{
    margin: 0 2%;
    width: 30%;
}

h4{
    color: rgb(150, 0, 0);
    margin: 0 35%;
    font-size: x-large;
    width: 30%;
    letter-spacing: 2px;
}

.learn-container h3{
    color: whitesmoke;
    background-color: rgb(150, 0, 0);
    font-size: 40px;
    margin: 0 12%;
    font-weight: lighter;
    font-family: 'Arial Narrow';
    letter-spacing: 1px;

}

.learn-container{
    position: relative;
    bottom: 100px;
}


.image-spot{
    position: absolute;
    background: url('../images/logowhite.png');
    background-size: cover;
    min-height: 105%;
    top: -5%;
    
}

.image-spot img{
    width: 80%;
    height: auto;
}

.subscribe-container{
    padding-top: 5%;
}

@media (max-width: 1000px) {
    .homepage{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .rompala-home-logo{
        display: flex;
        margin-top: 5%;
        min-height: 20vh;
        
        .image-spot{
            width: 100%;
        }
    }

    .homepage{
        padding: 0;
        padding-top: 10%;
        margin: 0;
    }

    video{
        max-width: 100%;
        max-height: 20vh;

    }

    .tagline1{
        position: absolute;
        top: 100%;
        font-size: small;
        
    }

    .grabber{
        display: flex;
        padding: 20% 0;
        font-size: x-large;
        border: #dadada;
        text-align: center;
    }

    .about-home{
        text-align: center;
        font-size: 12px;
    }

    .container{
        display: flexbox;
        
        width: 100%;
    }

    .img-container{
        width: 100%;
        background: url('../images/image1.jpg');
        background-size: cover;
        height: auto;
    }

    .txt-container{
        width: 100%;
        list-style: none;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        

        #li1{
            font-size: x-small;
            padding: 0;
        }

        span{
            padding: 0;
        }

        
    }

    #guarantee{
        font-size: large;
    }

    .learn-container h3{
        font-size: large;
        position: relative;
        top: 75px;
    }

    
}
