.about-home{
    display: flexbox;
    text-align: center;
    letter-spacing: 3px;
}

.subpage-header{
    display: flexbox;
    height: 50vh;
    background: url('../images/header.jpg');
    background-size: cover;
    padding-top: 5%;
}

.image-spot2{
    display: flexbox;
    max-width: 100vw;
    margin: 0 20%;
    min-height: 100%;
    background: url('../images/logowhite.png');
    background-size: 100%;
    
}

.services{
    .container{
        margin: 0;
        width: 100%;
        padding-bottom: 5%;
    }

    .txt-container{
        font-size: 36px;
    }
}

.about-home img{
    width: auto;
    height: 450px;
    float: inline-start;
    min-width: 400px;
}

h4{
    background-color: rgb(150, 0, 0);
    color: whitesmoke;
    font-size: 42px;
    width: 30%;
    margin: 0 35%;
    letter-spacing: 0;
    font-family: 'Arial Narrow';
    font-weight: lighter;
}

.about-home h1{
    font-size: 72px;
    font-family: sans-serif;
}

.about-home p{
    font-size: x-large;
    
}

#free{
    display: inline;
    font-weight: 800;
}

iframe{
    width: 560px;
    height: 315px;
}

.video-examples{
    padding-bottom: 5%;
    display: flex;
}

.subscribe-container2{
    margin: 0 20%;
}

.social-services img{
    height: 60%;
}

.social-services{
    margin-left: 7%;
}

@media (max-width: 1000px) {
    h1{
        display: none;
    }

    .subpage-header{
        max-height: 20vh;
        padding: 5% 0;
        margin: 0;
        background-size: cover;
        width: 100vw;
        
        overflow: hidden;
        
        .image-spot2{
            
            margin: 0;
            width: 100vw;
            max-height: 25vh;
        }
    }

    .tagline{
        font-size: medium;
        font-family: sans-serif;
    }

    .about-home p{
        display: flexbox;
        font-size: small;
        line-height: 180%;
    }

    .container{
        display: block;
        height: fit-content;

        .img-container{
            width: 100%;
            margin: 1%;
            height: 20vh;
            background-size: cover;
            
        }

        .txt-container{
            width: 100%;
           

            #li1{
                font-size: large;
                text-align: center;
            }

            span{
                padding-left: 5%;
            }

            
        }
    }

    .about-home li{
        text-align: center;
        display: flexbox;
        font-size: medium;
        padding: 5% 0;
    }

    h4{
        font-size: medium;
        width: 80%;
        margin: 0 10%;
    }

    .video-examples{
        font-size: smaller;
        display: flow-root;

        .video-card{
            padding-left: 5%;
            max-width: 80%;
            margin-top: 15%;

            h3{
                
                width: 90vw;
            }
        }
    }

    iframe{
        width: 340px;
        height: 200px;
    }
}