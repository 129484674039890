#about-list li{
    text-align: center;
    font-weight: bold;
}

#about-list{
    display: inline-flex;
    list-style: none;
    color: rgb(150, 0, 0);
    font-size: xx-large;
}


#aboutp{
    font-size: x-large;
    letter-spacing: 4px;
    float: right;
    text-align: left;
}

#headshot{
    margin: 2%;
}

.img-about{
    display: inline-flex;
    margin: 0;
    width: 100%;
    height: fit-content;

    #img-mid{
        margin: 1% 5%;
    }

    img{
        margin: 1% 0;
        width: 100%;
        height: auto;
    }
}


@media (max-width: 700px) {
    #aboutp{
        display: flow-root;
        max-width: 90%;
        margin: 0 5%;
        text-align: center;
        letter-spacing: 1px;
        font-size: medium;
        padding-bottom: 15%;
        
    }

   #headshot{
            display: none;
        }

    #about-list{
        display: block;
        padding-top: 300%;
        font-size: smaller;
    }

    .img-about{
        display: flow-root;
        padding: 0;
        width: 150%;
        align-items: center;
        margin: 0;
        
        
    }

    .img{
        margin: 0;
        padding: 0;
        max-width: 20%;
        display: none;
        
        }

    #img-mid{
        display: none;
    }

    .closed{
        display: none;
    }

    
}