.footer{
    width: 100%;
    background-color: #000000;
    justify-content: center;
    text-align: center;
    color: whitesmoke;
    padding: 0;

    
}

.footer-header{
    display: inline-flex;
    list-style: none;
    width: 100%;
    height: fit-content;
    justify-content: center;
    background-color: rgb(150, 0, 0);

    .open{
        padding: 0 3%;
        color: whitesmoke;
        font-family: 'Arial Regular';
        font-weight: bolder;
        font-size: x-large;
        letter-spacing: 2px;

        p{
            font-weight: lighter;
            font-style: italic;
            font-family: 'Arial Narrow';
            padding: 0;
            margin: 0;
        }
    }
    .closed{
        color: #000000;
        padding: 0 2%;
        
        font-family: 'Arial Regular';
        font-weight: bolder;
        font-size: x-large;
        letter-spacing: 2px;

        p{
            font-weight: lighter;
            font-style: italic;
            font-family: 'Arial Narrow';
            padding: 0;
            margin: 0;
        }
    }
}

.footer-body{
    width: 100%;
    display: inline-flex;
    background-color: #000000;
    height: fit-content;
    padding: 0;
}

.footer-subscribe{
    width: 70%;
    padding-top: 5%;
}

.footer-logo{
    display: block;
    width: 30%;
    font-family: 'Arial Narrow';
    font-size: x-large;
    padding: 0;
    margin: 0;

    .image-footer{
        height: 220px;
        background: url('../images/logowhite.png');
        background-size: cover;
    }

    .address{
        width: 100%;
        font-size: xx-large;
        letter-spacing: 2px;
        position: relative;
        top: -20px;
        text-align: left;
        padding-left: 10%;
    }

    .footer-links{
        width: 80%;
        height: 50px;
        padding: 0;
        margin: 0;
        display: inline-flex;
        position: relative;
        top: -50px;
        font-size: xx-large;
    }

    .sm-imgs{
        width: 50%;

        img{
            min-width: 20%;
            padding-right: 10%;
        }
    }
}


@media (max-width: 1000px) {
    .footer{
        display: block;
        max-width: 100vw;
        overflow: hidden;

        .footer-header{
            display: flex;
            font-size: xx-small;
            

            .open{
                font-size: xx-small;
                font-weight: normal;
                margin: 1%;
                padding: 0 1%;
                
            }

            .closed{
                display: none;
            }
            
        }

        .footer-body{
            display: flexbox;
            
        }

        .footer-logo{
            display: flex;
            width: 200%;

            .image-footer{
                display: none;
            }

            .address{
                width: 100%;
                font-size: xx-small;
                text-align: center;
                padding-top: 10%;
            }

            .footer-links{
                font-size: medium;
                display: flow-root;
                position: relative;
                top: 10%;
                width: 100%;
                text-align: left;
                
                
            
            }
        }

        .footer-subscribe{
            
            display: block;
            padding-bottom: 5%;
            
        }
    }
}
